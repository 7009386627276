import React, { useCallback, useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import '../CreateCampaign/createCampaign.scss';
import CustomButton from '../../../uiKit/Button/CustomButton';

import Header from '../../../components/Header/header';
import CampaignMessages from './HelperComponents/campaignMessages';
import CampaignsInfos from './HelperComponents/campaignInfos';
import CampaignWarning from './HelperComponents/campaignWarning';

import {
    clearCampaigns,
    getCampaignDraft,
    launchCampaignDraft,
    updateCampaignDraft,
} from '../../../redux/state/campaignSlice';
import { toast } from 'react-toastify';

const DraftCampaign = () => {

    const isLoading = useSelector(state => state.campaign.isLoading.launchCampaignDraft, shallowEqual);
    const isLoadingDraft = useSelector(state => state.campaign.isLoading.updateCampaignDraft, shallowEqual);
    const isLoadingGetDraft = useSelector(state => state.campaign.isLoading.getCampaignDraft, shallowEqual);

    const history = useHistory();
    const dispatch = useDispatch();
    const { campaignId } = useParams();
    const communityId = useSelector(state => state.community.community.id, shallowEqual);

    const [draftMessages, setDraftMessages] = useState(null);
    const [messages, setMessages] = useState(null);
    const [excludedMembers, setExcludedMembers] = useState([]);

    const [editedCampaign, setEditedCampaign] = useState({
        id: null,
        name: '',
        campaignTypeId: 0,
        recipient: {
            userId: null,
            fullName: null
        },
        amount: 0,
        recipientId: null,
        description: '',
        excludedMembers: [],
    });

    useEffect(() => {
        if (!!communityId && !!campaignId) {
            dispatch(getCampaignDraft({ communityId, campaignId }))
                .then(unwrapResult)
                .then(response => {
                    const {
                        id,
                        name,
                        campaignTypeId,
                        recipient,
                        recipientId,
                        amount,
                        description,
                        messages,
                        excludedMembers
                    } = response.campaign;
                    const ids = excludedMembers.map(member => member.userId)
                    setEditedCampaign({
                        id,
                        name,
                        campaignTypeId,
                        recipient,
                        amount,
                        recipientId,
                        description,
                        excludedMembers: ids
                    });
                    setExcludedMembers(excludedMembers);
                    setDraftMessages(messages);
                })
                .catch(({ title }) => toast.info(title))
        }
    }, [communityId, campaignId, dispatch])

    const saveHandler = () => {

        const data = { ...editedCampaign, communityId, messages }
        dispatch(launchCampaignDraft({
            communityId,
            campaignId,
            data
        }))
            .then(unwrapResult)
            .then((res) => {
                dispatch(clearCampaigns());
                setTimeout(() => {
                    history.push(`/campaigns/campaign/active/${res.campaign.id}`)
                }, [1000])
            })
            .catch(({ title }) => {
                window.scrollTo(0, 0);
                toast.info(`Launching draft failed: ${title}`)
            })
    }

    const saveDraftHandler = () => {

        const data = { ...editedCampaign, communityId, messages }

        dispatch(updateCampaignDraft({
            communityId,
            campaignId,
            data
        }))
            .then(unwrapResult)
            .then(() => history.push('/settings/admin/drafts/draft'))
            .catch(({ title }) => {
                window.scrollTo(0, 0);
                toast.info(`Saving draft failed: ${title}`)
            })
    }

    const updateMessages = useCallback((newMessages) => setMessages(newMessages), [])

    return (
        <div>
            <Header title='Drafts' onClick={() => history.push('/settings/admin/drafts/draft')} />
            <div className='titleInputWrapper'>
                <CampaignsInfos
                    campaign={editedCampaign}
                    setCampaign={setEditedCampaign}
                    excluded={excludedMembers}
                />
                <div className='divider' style={{ marginBottom: '8px' }} />
                <CampaignMessages
                    messages={draftMessages}
                    updateMessages={updateMessages}
                    isLoading={isLoadingGetDraft}
                />
                <div className='divider' />
                <CampaignWarning />
                <div className='createCampaignButtons' >
                    <CustomButton
                        title='Launch'
                        style={{ marginBottom: '16px' }}
                        classNames='mr16to0'
                        onClick={saveHandler}
                        disabled={isLoading}
                        loading={isLoading}
                    />
                    <CustomButton
                        isWhite
                        title='Save as Draft'
                        onClick={saveDraftHandler}
                        disabled={isLoadingDraft}
                        loading={isLoadingDraft}
                    />
                </div>
            </div >
        </div >
    )

}

export default DraftCampaign;