import Login from '../../pages/Login/login';
import LinkInvitationLogin from '../../pages/Login/linkInvitationLogin';
import SignUp from '../../pages/Account/CreateAccount/signUp';
import AccountSetup from '../../pages/Account/AccountSetup/accountSetup';
import ResetPassword from '../../pages/Login/resetPassword';
import NewPassword from '../../pages/Login/newPassword';
import CreateAccount from '../../pages/Account/CreateAccount/createAccount';
import FindCommunity from '../../pages/Account/FindCommunity/findCommunity';
import CreatCommunity from '../../pages/Account/CreateCommunity/createCommunity';
import Created from '../../pages/Account/CreateCommunity/created';
import EmailRequired from '../../components/AlternativeLogin/emailRequired';

import PrivacyAndPolicy from '../../pages/Account/LandingPage/TermsWithRoutes/policy';
import TermsAndConditions from '../../pages/Account/LandingPage/TermsWithRoutes/terms';

import Campaigns from '../../pages/Campaigns/campaigns';
import CreateCampaign from '../../pages/Campaigns/CreateCampaign/createCampaign';
import DonationAmount from '../../pages/Campaigns/Campaign/donationAmount';
import ActiveCampaign from '../../pages/Campaigns/Campaign/ActiveCampaign/activeCampaign';
import CompletedCampaign from '../../pages/Campaigns/Campaign/CompletedCampaign/completedCampaign';
import RepeatCampaign from '../../pages/Campaigns/CreateCampaign/repeatCampaign';
import TransferToCampaign from '../../pages/Campaigns/Campaign/transfer';
import CampaignThankYou from '../../pages/Campaigns/Campaign/thankYou';
import InviteFriend from '../../pages/Campaigns/Campaign/inviteFriend';
import SuggestCampaign from '../../pages/Campaigns/CreateCampaign/suggestCampaign';
import SuggestedCampaign from '../../pages/Campaigns/CreateCampaign/suggestedCampaign';

import MembersWrapper from '../../pages/Members/membersWrapper';
import AddMemberManual from '../../pages/AddMember/addMembersManual';
import AddMember from '../../pages/AddMember/addMember';
import AddSingleMember from '../../pages/AddMember/addMemberSingle';
import MemberInfo from '../../components/MemberInfo/memberInfo';

import DraftsWrapper from '../../pages/Drafts/draftsWrapper';
import DraftCampaign from '../../pages/Campaigns/CreateCampaign/draftCampaign';
import AdminsInfo from '../../pages/PersonalSettings/Admins/adminsInfo';

import OurBalance from '../../pages/OurBalance/ourBalance';
import OurBalanceDonationAmount from '../../pages/OurBalance/donationAmountOurBalance';
import OurBalanceThankYou from '../../pages/OurBalance/thankYou';

import Checkout from '../../pages/Checkout/checkout';

import MyCommunities from '../../pages/PersonalSettings/MyCommunities/myCommunities';
import FindCommunityWithSidebar from '../../pages/PersonalSettings/MyCommunities/FindCommunity/findCommunityWithSidebar';
import NewCommunity from '../../pages/PersonalSettings/CreateNewCommunity/newCommunity';
import CreatedWithMenu from '../../pages/PersonalSettings/CreateNewCommunity/created';

import PersonalInfo from '../../pages/PersonalSettings/PersonalInfo/personalInfo';
import SecuritySettings from '../../pages/PersonalSettings/SecuritySettings/securitySettings';
import VerificationEmail from '../../pages/PersonalSettings/SecuritySettings/verificationEmail';
import Subscriptions from '../../pages/PersonalSettings/Subscriptions/subscriptions';

import PaymentPreferences from '../../pages/PersonalSettings/PaymentPreferences/paymentPreferences';
import RecievingMethod from '../../pages/PersonalSettings/PaymentPreferences/receivingMethod';

import ContactUs from '../../pages/PersonalSettings/ContactUs/contactUs';
import Tutorials from '../../pages/Account/LandingPage/tutorials';
import FAQ from '../../pages/Account/LandingPage/faq';
import OurValues from '../../pages/Account/LandingPage/ourValues';

import UnauthorizedActiveCampaign from '../../pages/UnauthorizedPages/activeCampaign';
import UnauthorizedDonate from '../../pages/UnauthorizedPages/donate';
import UnauthorizedThankYou from '../../pages/UnauthorizedPages/thankyou';
import UnauthorizedCampaignNotFound from '../../pages/UnauthorizedPages/campaignNotFound';

export const AccountComponents = {
    Login,
    LinkInvitationLogin,
    ResetPassword,
    NewPassword,
    SignUp,
    CreateAccount,
    AccountSetup,
    CreatCommunity,
    Created,
    FindCommunity,
    EmailRequired,
}

export const TermsComponents ={
    Privacy: PrivacyAndPolicy,
    Terms: TermsAndConditions
}

export const CampaignComponents = {
    Campaigns,
    Create: CreateCampaign,
    Amount: DonationAmount,
    Transfer: TransferToCampaign,
    Active: ActiveCampaign,
    Completed: CompletedCampaign,
    Repeat: RepeatCampaign,
    Checkout,
    ThankYou: CampaignThankYou,
    InviteFriend,
    Suggest: SuggestCampaign,
    Draft: SuggestedCampaign,
    AddMember: AddSingleMember
}

export const MemberComponents = {
    MembersWrapper,
    AddMemberManual,
    AddMember,
    MemberInfo
}

export const AdminSettingsComponents = {
    DraftsWrapper,
    DraftCampaign,
    AdminsInfo
}

export const OurBalanceComponents = {
    OurBalance,
    Amount: OurBalanceDonationAmount,
    ThankYou: OurBalanceThankYou,
    Checkout
}

export const SettingsComponents = {
    Communities: {
        MyCommunities,
        Find: FindCommunityWithSidebar,
        New: NewCommunity,
        Created: CreatedWithMenu
    },
    AccountInfo: {
        Info: PersonalInfo,
        Security: SecuritySettings,
        VerificationEmail,
        Subscriptions
    },
    PaymentPreferences: {
        Preferences: PaymentPreferences,
        RecievingMethod,
    },
    ContactUs,
    Tutorials,
    FAQ,
    OurValues,
}

export const UnauthorizedComponents = {
    Campaign: UnauthorizedActiveCampaign,
    Donate: UnauthorizedDonate,
    ThankYou: UnauthorizedThankYou,
    NotFound: UnauthorizedCampaignNotFound,
    Checkout
}