export const validateEmail = (email) => {
    const mailformat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return mailformat.test(email);
}

export const validateName = (str) => {
    const regex = /^([A-Za-z \\.'`-]{2,})$/;
    return regex.test(str);
}

export const validateAddress = (str) => {
    const regex = /^([a-zA-Z0-9\s,.'-]){1,120}$/;
    return regex.test(str);
}

export const validateCampaignName = (str) => {
    const regex = /^([A-Za-z '`-]{2,})$/;
    return regex.test(str);
}

export const validateDigit = (str) => {
    const regex = /^([0-9]{1,})$/;
    return regex.test(str);
}

export const validateNameWithSpecialSimvols = (str) => {
    const regex = /^([A-Za-z0-9\s`':«»&"-]{2,})$/;
    return regex.test(str);
}

export const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,20})/;
    return regex.test(password);
}

export const onlyNumbers = (string) => {
    const regex = /^([0-9]*)$/;
    return regex.test(string)
}