import { FetchAPI } from "./main";

export const AccountAPI = {

    // identityAPI

    startRegistration: (data) => FetchAPI.postJson('/identity/account/start-registration', data),
    completeRegistration: (data) => FetchAPI.postJson('/identity/account/complete-registration', data),
    login: (data) => FetchAPI.postJson('/identity/account/login', data),
    refreshToken: (refreshToken) => FetchAPI.postJson(`/identity/account/refresh?refreshToken=${refreshToken}`),
    checkPassword: ({ password }) => FetchAPI.postJson(`/identity/account/check-password`, { password }),
    startChangeEmail: (data) => FetchAPI.postJson('/identity/account/start-change-email', data),
    completeChangeEmail: (data) => FetchAPI.postJson('/identity/account/complete-change-email', data),
    forgetPassword: (email) => FetchAPI.postJson(`/identity/account/forgot-password?email=${decodeURIComponent(email)}`),
    startChangePassword: () => FetchAPI.postJson('/identity/account/start-change-password'),
    completeChangePassword: (data) => FetchAPI.postJson('/identity/account/complete-change-password', data),
    resendLink: (data) => FetchAPI.postJson('/identity/account/resend-link', data),

    //externalAuth

    externalLogin: ({
        email,
        accessToken,
        provider
    }) => {

        const query = new URLSearchParams();
        if (!!email) query.append('email', email);
        if (!!accessToken) query.append('accessToken', accessToken);
        if (!!provider) query.append('provider', provider);

        return FetchAPI.postJson(`/identity/account/external/login?${query.toString()}`)
    },
    //userAPI

    parseToken: (token) => FetchAPI.getJson(`/user/invitations/parse?token=${token}`),
    inviteUser: (user) => FetchAPI.postJson('/user/invitations', user),
    confirmInvitation: (invitationToken) => FetchAPI.postJson(`/user/invitations/confirm?token=${invitationToken}`),

    getUsers: ({
        pageSize,
        page,
        searchString = ''
    }) => FetchAPI.getJson(`/user/users/${pageSize}/${page}?searchString=${searchString}`),
    getUser: () => FetchAPI.getJson('/user/account'),
    updateUser: (data) => {
        const {
            userId,
            file,
            firstName,
            lastName,
            phoneNumber,
            addressLine,
            zipCode,
            city,
            state,
            preferredCommunicationMethod
        } = data;
        const formData = new FormData();

        formData.append('id', userId);
        formData.append('file', file);
        formData.append('firstName', firstName);
        formData.append('lastName', lastName);
        formData.append('phoneNumber', phoneNumber);
        formData.append('addressLine', addressLine);
        formData.append('zipCode', zipCode);
        formData.append('city', city);
        formData.append('state', state);
        formData.append('preferredCommunicationMethod', preferredCommunicationMethod);

        return FetchAPI.put('/user/account', formData);
    },
    deleteUser: () => FetchAPI.deleteJson('/user/account'),
}