import { FetchAPI } from "./main";

export const CampaignAPI = {
    getCampaignTypes: () => FetchAPI.get('/community/campaigns/types'),
    createCampaign: (
        communityId,
        data
    ) => FetchAPI.postJson(`/community/communities/${communityId}/campaigns`, data),
    //pagination
    getActiveCampaigns: ({
        communityId,
        pageSize,
        page
    }) => FetchAPI.getJson(`/community/communities/${communityId}/campaigns/active/${pageSize}/${page}`),
    getActiveCampaignsPublic: ({
        communityId,
        pageSize,
        page
    }) => FetchAPI.getJsonPublic(`/community/communities/${communityId}/campaigns/active/${pageSize}/${page}`),
    getCompletedCampaigns: ({
        communityId,
        pageSize,
        page
    }) => FetchAPI.getJson(`/community/communities/${communityId}/campaigns/completed/${pageSize}/${page}`),
    //campaignId
    getCampaign: ({
        communityId,
        campaignId
    }) => FetchAPI.getJson(`/community/communities/${communityId}/campaigns/${campaignId}`),
    getCampaignPublic: ({
        communityId,
        campaignId
    }) => FetchAPI.getJsonPublic(`/community/communities/${communityId}/campaigns/${campaignId}`),
    getStoppedCampaign: ({
        communityId,
        campaignId
    }) => FetchAPI.getJson(`/community/communities/${communityId}/campaigns/stopped/${campaignId}`),
    getCompletedCampaign: ({
        communityId,
        campaignId
    }) => FetchAPI.getJson(`/community/communities/${communityId}/campaigns/completed/${campaignId}`),
    getCompletedCampaignPublic: ({
        communityId,
        campaignId
    }) => FetchAPI.getJsonPublic(`/community/communities/${communityId}/campaigns/completed/${campaignId}`),
    stopCampaign: ({
        communityId,
        campaignId
    }) => FetchAPI.postJson(`/community/communities/${communityId}/campaigns/${campaignId}/stop`),
}