import React, { useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { unwrapResult } from "@reduxjs/toolkit";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ReactGA from 'react-ga4';

import './communityMenu.scss';
import { InputBase, MenuItem, Select } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { Arrow } from "../../../uiKit/assets/svg/icons";
import { blue050, gray300, gray900 } from "../../../uiKit/assets/colors/colors";

import GeneralMenu from "./generalMenu";

import { ACCOUNT_SETUP_ERROR } from "../../../helpers/constants";

import {
    clearCommunity,
    clearId,
    clearMemberCommunities,
    getCommunities,
    getCommunity,
    setCommunityId
} from "../../../redux/state/communitySlice";
import { setImageURL } from '../../../redux/state/communitySlice';
import {
    clearCampaigns,
    clearDrafts,
    clearSuggestions
} from "../../../redux/state/campaignSlice";
import { clearAdmins, getAdmins } from "../../../redux/state/memberSlice";
import { getUser, refreshToken } from "../../../redux/state/accountSlice";
import { clearLoadedOnChangeCommunity, setLoaded, setScreenWidth } from "../../../redux/state/commonSlice";
import { clearNotifications, getInvitationMessage } from "../../../redux/state/notificationSlice";

const ArrowComponent = ({ className, ...rest }) => (
    <div
        {...rest}
        style={{
            right: '24px',
            lineHeight: '14px'
        }}
        className={className}
    >
        <Arrow />
    </div>
);


const useStyles = makeStyles(() => ({
    paper: {
        boxShadow: 'none'
    },
    selected: {
        backgroundColor: `transparent !important`,
    },
    root: {
        '&:hover': {
            backgroundColor: `${blue050} !important`
        },
        justifyContent: 'flex-start !important'
    }
}))

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 280,
            width: 312,
            marginLeft: -8,
            boxShadow: '0px 4px 10px rgba(224, 238, 242, 0.8)',
            paddingTop: 0,
            marginTop: -56
        },
    },
    getcontentanchorel: null,
};

const CommunityMenu = ({ closeMenu = () => { } }) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const selectClasses = useStyles();

    const { campaignId } = useParams();

    const imageUrl = useSelector(state => state.community.communityImageURL, shallowEqual);
    const defaultCommunityId = useSelector(state => state.community.communityId, shallowEqual);
    const community = useSelector(state => state.community.community, shallowEqual);
    const memberCommunities = useSelector(state => state.community.memberCommunities, shallowEqual);
    const userDetails = useSelector(state => state.account.userDetails, shallowEqual);
    const notFound = useSelector(state => state.campaign.notFound, shallowEqual);
    const notFoundCommon = useSelector(state => state.common.notFound, shallowEqual);
    const admins = useSelector(state => state.member.communityAdmins, shallowEqual);

    const addMembers = JSON.parse(window.localStorage.getItem('add-members'));

    const screenWidth = useSelector(state => state.common.screenWidth, shallowEqual);

    useEffect(() => {
        window.addEventListener('resize', () => dispatch(setScreenWidth(window.innerWidth)));
        return () => window.removeEventListener('resize', () => dispatch(setScreenWidth(window.innerWidth)))
    }, [screenWidth, dispatch])

    useEffect(() => {
        if (addMembers && !!admins) {
            history.push('/members/add-members');
            window.localStorage.setItem('add-members', false);
        }
    }, [addMembers, admins, history])

    useEffect(() => {
        const storedToken = window.localStorage.getItem('token');
        if (!!storedToken) {
            const parsedtoken = JSON.parse(storedToken);
            const interval = setInterval(() => {
                const token = window.localStorage.getItem('refresh-token');
                if (!!token) {
                    dispatch(refreshToken(token));
                }
            }, [(parsedtoken.expires_in - 10) * 1000])

            return () => clearInterval(interval)
        }
    }, [dispatch])

    useEffect(() => {
        if (!userDetails.id) {
            dispatch(getUser())
                .then(unwrapResult)
                .then(() => dispatch(setLoaded({ type: 'account', value: true })))
        }
    }, [userDetails, dispatch])

    useEffect(() => {
        if (!admins && !!community.id) {
            dispatch(getAdmins(community.id))
                .then(unwrapResult)
                .then(() => dispatch(setLoaded({ type: 'admins', value: true })))
        }
    }, [admins, community.id, dispatch])

    useEffect(() => {
        if (notFoundCommon) {
            history.push('/not-found')
        }
    }, [notFoundCommon, history])

    useEffect(() => {
        if (notFound && !!campaignId && !!community.id && !community.role) {
            history.push(`/campaigns/campaign-completed/${community.id}/${campaignId}`)
        }
    }, [notFound, history, campaignId, community?.role, community?.id])

    useEffect(() => {
        if (!!defaultCommunityId && !community.id) {
            dispatch(getCommunity(defaultCommunityId))
                .then(unwrapResult)
                .then(response => {
                    const { community } = response;
                    window.localStorage.setItem('isAdmin', !!community.role);
                    if (screenWidth < 900) {
                        dispatch(setImageURL(community.images.mobile));
                    }
                    else {
                        dispatch(setImageURL(community.images.desktop));
                    }
                    if (!!response.community.role) {
                        dispatch(getInvitationMessage(defaultCommunityId));
                    }

                    dispatch(setLoaded({ type: 'community', value: true }));

                })
                .catch(({ title }) => {
                    toast.info(title);
                    dispatch(clearId());
                    dispatch(clearMemberCommunities());
                });
        }
    }, [
        community,
        defaultCommunityId,
        screenWidth,
        dispatch,
        history
    ])

    useEffect(() => {

        const communityId = window.localStorage.getItem('communityId');

        if (!memberCommunities) {
            dispatch(getCommunities())
                .then(unwrapResult)
                .then(response => {
                    const { communities } = response;

                    if (!!communities && !!communities.length && !!communityId && !defaultCommunityId) {
                        const community = communities.find(community => community.id === communityId);
                        if (!!community) {
                            dispatch(setCommunityId({ communityId }));
                            window.localStorage.setItem('communityId', communityId);
                        } else {
                            const community = communities.find(community => community.isConfirmed);
                            if (!!community) {
                                dispatch(clearCommunity());
                                dispatch(setCommunityId({ communityId: community.id }))
                                window.localStorage.setItem('communityId', community.id);
                            } else {
                                history.push('/account-setup')
                                toast.info('Your communities waiting for approval');
                            }
                        }
                    }

                    if (!!communities && !!communities.length && !communityId) {
                        const community = communities.find(community => community.isConfirmed);
                        if (!!community) {
                            dispatch(clearCommunity());
                            dispatch(setCommunityId({ communityId: community.id }))
                            window.localStorage.setItem('communityId', community.id);
                        } else {
                            history.push('/account-setup')
                            toast.info('Your communities waiting for approval');
                        }
                    }

                    if (!!communities && !communities.length) {
                        toast.info(ACCOUNT_SETUP_ERROR);
                        history.push('/account-setup')
                    }
                })
        }
    }, [
        memberCommunities,
        history,
        defaultCommunityId,
        dispatch,
    ])

    const handleChange = (e) => {
        const communityId = e.target.value;
        if (!!communityId) {
            ReactGA.event('community_change_menu');
            closeMenu();
            dispatch(clearCommunity());
            dispatch(clearCampaigns());
            dispatch(clearAdmins());
            dispatch(clearDrafts());
            dispatch(clearSuggestions());
            dispatch(clearNotifications());
            dispatch(setCommunityId({ communityId }));
            dispatch(clearLoadedOnChangeCommunity());
            window.localStorage.setItem('communityId', communityId);
            history.push('/campaigns');
        } else {
            toast.info('Your community waiting for approval');
        }
    }

    return (
        <div className='communityWrapper'>
            <div className='contentWrapper'>
                <Select
                    value={defaultCommunityId}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    IconComponent={ArrowComponent}
                    fullWidth
                    input={
                        <InputBase
                            className='customSelect'
                        />
                    }
                    MenuProps={MenuProps}
                >
                    <MenuItem
                        key={defaultCommunityId}
                        value={defaultCommunityId}
                        style={{
                            padding: '8px 0 8px 4px',
                            marginLeft: '14px',
                            borderRadius: '12px',
                            marginTop: '24px'
                        }}
                        classes={selectClasses}
                    >
                        <div style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <img
                                src={imageUrl}
                                alt=''
                                style={{
                                    borderRadius: '50%',
                                    marginRight: '12px',
                                    width: '40px',
                                    height: '40px',
                                    objectFit: 'cover'
                                }}
                            />
                            <div style={{
                                fontSize: '14px',
                                fontWeight: 600,
                                lineHeight: '20px',
                                fontFamily: 'Poppins',
                                color: gray900,
                                maxWidth: '170px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>{community?.name}</div>
                        </div>
                    </MenuItem>
                    <div style={{
                        width: '276px',
                        border: `1px solid ${gray300}`,
                        margin: '10px 20px'
                    }}
                    />
                    {
                        !!memberCommunities && memberCommunities
                            .filter(e => e.id !== defaultCommunityId)
                            .map((community, index) => (
                                <MenuItem
                                    key={index}
                                    value={community.isConfirmed ? community.id : ''}
                                    style={{
                                        padding: '8px 0 8px 4px',
                                        marginLeft: '14px',
                                        borderRadius: '12px'
                                    }}
                                    classes={selectClasses}
                                >
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <img
                                            src={community.images.mobile}
                                            alt=''
                                            style={{
                                                borderRadius: '50%',
                                                marginRight: '12px',
                                                width: '40px',
                                                height: '40px',
                                                objectFit: 'cover'
                                            }}
                                        />
                                        <div style={{
                                            fontSize: '14px',
                                            fontWeight: 600,
                                            lineHeight: '20px',
                                            fontFamily: 'Poppins',
                                            color: gray900,
                                            maxWidth: '170px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }}>{community.name}</div>
                                    </div>
                                </MenuItem>
                            ))
                    }
                </Select>
                <GeneralMenu onClose={() => closeMenu()} />
            </div>
        </div>
    )
}

export default CommunityMenu;