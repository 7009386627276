import React, { useEffect, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { isPossiblePhoneNumber } from 'react-phone-number-input';

import { COMMUNICATION_METHOD, PASSWORD_ERROR } from "../../../helpers/constants";
import { validateAddress, validateName, validatePassword } from "../../../helpers/validation";

import { completeRegistration, parseToken } from "../../../redux/state/accountSlice";
import { setCommunityId } from "../../../redux/state/communitySlice";

import { Eye, Location, Mail } from "../../../uiKit/assets/svg/icons";
import CustomButton from "../../../uiKit/Button/CustomButton";
import CustomInput from "../../../uiKit/Input/CustomInput";
import Title from "../../../uiKit/Title/Title";
import CustomPhoneInput from "../../../uiKit/Input/CustomPhoneInput";
import CustomRadio from "../../../uiKit/CustomRadio/customRadio";

const CreateAccount = () => {

    const isLoading = useSelector(state => state.account.isLoading.completeRegistration, shallowEqual);
    const isLoadingParseToken = useSelector(state => state.account.isLoading.parseToken, shallowEqual);

    const history = useHistory();
    const dispatch = useDispatch();

    const user = useSelector(state => state.account.userByParseToken, shallowEqual);

    const { search } = useLocation();
    const token = new URLSearchParams(search).get('token');

    const [disabled, setDisabled] = useState(true);
    const [showPassword, setShowPassword] = useState(false);

    const [account, setAccount] = useState({
        firstName: null,
        lastName: null,
        phoneNumber: null,
        addressLine: null,
        zipCode: null,
        city: null,
        state: null,
        password: null,
        confirmPassword: null,
        preferredCommunicationMethod: 1
    })

    const [errors, setErrors] = useState({
        firstNameError: null,
        lastNameError: null,
        phoneNumberError: null,
        addressLineError: null,
        zipCodeError: null,
        cityError: null,
        stateError: null,
        passwordError: null,
        confirmPasswordError: null,
        hasError: function () {
            return (
                !!this.firstNameError ||
                !!this.lastNameError ||
                !!this.phoneNumberError ||
                !!this.addressLineError ||
                !!this.zipCodeError ||
                !!this.cityError ||
                !!this.stateError ||
                !!this.passwordError ||
                !!this.confirmPasswordError
            )
        }
    })

    useEffect(() => {
        if (!!token) {
            dispatch(parseToken(token))
                .then(unwrapResult)
                .then(response => {
                    const { user } = response;
                    setAccount(prev => ({
                        ...prev,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        phoneNumber: user.phoneNumber,
                    }))
                })
                .catch(({ title }) => console.error(title))
        }
    }, [token, dispatch])

    const onFirstNameChange = (e) => {
        const value = e.target.value;
        setAccount(prev => ({ ...prev, firstName: value }));
        if (!validateName(value)) {
            setErrors(prev => ({ ...prev, firstNameError: 'Invalid first name' }));
        } else {
            setErrors(prev => ({ ...prev, firstNameError: null }));
        }
    }

    const onLastNameChange = (e) => {
        const value = e.target.value;
        setAccount(prev => ({ ...prev, lastName: value }));
        if (!validateName(value)) {
            setErrors(prev => ({ ...prev, lastNameError: 'Invalid last name' }));
        } else {
            setErrors(prev => ({ ...prev, lastNameError: null }));
        }
    }

    const onPhoneNumberChange = (value) => {
        setAccount(prev => ({ ...prev, phoneNumber: value }));
        if (!!value && isPossiblePhoneNumber(value)) {
            setErrors(prev => ({ ...prev, phoneNumberError: null }));
        } else {
            setErrors(prev => ({ ...prev, phoneNumberError: 'Invalid phone number' }));
        }
    }

    const onAddressLineChange = (e) => {
        const value = e.target.value;
        setAccount(prev => ({ ...prev, addressLine: value }));
        if (!validateAddress(value)) {
            setErrors(prev => ({ ...prev, addressLineError: 'Invalid address line' }));
        } else {
            setErrors(prev => ({ ...prev, addressLineError: null }));
        }
    }

    const onZipCodeChange = (e) => {
        const value = e.target.value;
        setAccount(prev => ({ ...prev, zipCode: value }));
        if (!!value) {
            setErrors(prev => ({ ...prev, zipCodeError: null }));
        } else {
            setErrors(prev => ({ ...prev, zipCodeError: 'Invalid zip code' }));
        }
    }

    const onCityChange = (e) => {
        const value = e.target.value;
        setAccount(prev => ({ ...prev, city: value }));
        if (!!value) {
            setErrors(prev => ({ ...prev, cityError: null }));
        } else {
            setErrors(prev => ({ ...prev, cityError: 'Invalid city name' }));
        }
    }

    const onStateChange = (e) => {
        const value = e.target.value;
        setAccount(prev => ({ ...prev, state: value }));
        if (!!value) {
            setErrors(prev => ({ ...prev, stateError: null }));
        } else {
            setErrors(prev => ({ ...prev, stateError: 'Invalid state' }));
        }
    }

    const onPasswordChange = (e) => {
        const value = e.target.value;
        setAccount(prev => ({ ...prev, password: value }));
        if (!validatePassword(value)) {
            setErrors(prev => ({ ...prev, passwordError: PASSWORD_ERROR }));
        } else {
            setErrors(prev => ({ ...prev, passwordError: null }));
        }
    }

    const onConfirmPasswordChange = (e) => {
        const value = e.target.value;
        setAccount(prev => ({ ...prev, confirmPassword: value }));
    }

    const onCommunicationMethodChange = (e) => {
        const value = e.target.value;
        setAccount(prev => ({ ...prev, preferredCommunicationMethod: parseInt(value) }))

    }

    useEffect(() => {
        if (!!account.confirmPassword) {
            if (account.password !== account.confirmPassword) {
                setErrors(prev => ({ ...prev, confirmPasswordError: 'Password does not match' }));
            } else {
                setErrors(prev => ({ ...prev, confirmPasswordError: null }));
            }
        }
    }, [account])

    useEffect(() => {
        if (
            !!account.firstName &&
            !!account.lastName &&
            !!account.phoneNumber &&
            !!account.addressLine &&
            !!account.zipCode &&
            !!account.city &&
            !!account.state &&
            !!account.password &&
            !!account.confirmPassword
        ) {
            if (errors.hasError()) {
                setDisabled(true);
            } else {
                setDisabled(false);
            }
        } else {
            setDisabled(true);
        }
    }, [account, errors])

    const createAccountHandler = () => {
        const accountInfo = {
            token,
            ...account
        }
        dispatch(completeRegistration(accountInfo))
            .then(unwrapResult)
            .then(() => {
                if (!!user.communityId) {
                    dispatch(setCommunityId({ communityId: user.communityId }));
                    window.localStorage.setItem('communityId', user.communityId);
                    history.push('/campaigns');
                } else {
                    history.push('/account-setup');
                }
            }).catch(({ title }) => console.error(title))
    }

    return (
        <div className='landingContentWrapper mobileHeight'>
            <Title
                text='Create Account'
                fontSize={32}
                lineHeight={40}
                style={{ marginBottom: '32px' }}
            />
            <div className='createAccountInputWrapper'>
                <CustomInput
                    label='First Name'
                    type='text'
                    onChange={onFirstNameChange}
                    value={account.firstName}
                    errorMessage={errors.firstNameError}
                    error={!!errors.firstNameError}
                    maxLength={30}
                />
                <CustomInput
                    label='Last Name'
                    type='text'
                    onChange={onLastNameChange}
                    value={account.lastName}
                    error={!!errors.lastNameError}
                    errorMessage={errors.lastNameError}
                    maxLength={30}
                />
                <CustomPhoneInput
                    label='Phone'
                    onChange={onPhoneNumberChange}
                    value={account.phoneNumber ?? user.phoneNumber}
                    error={!!errors.phoneNumberError}
                    errorMessage={errors.phoneNumberError}
                />
                <CustomInput
                    label='Address line'
                    type='text'
                    onChange={onAddressLineChange}
                    value={account.addressLine}
                    error={!!errors.addressLineError}
                    errorMessage={errors.addressLineError}
                    maxLength={120}
                />
                <CustomInput
                    label='Postal Code'
                    onChange={onZipCodeChange}
                    value={account.zipCode ?? ''}
                    error={!!errors.zipCodeError}
                    errorMessage={errors.zipCodeError}
                />
                <CustomInput
                    label='City'
                    onChange={onCityChange}
                    value={account.city ?? ''}
                    error={!!errors.cityError}
                    errorMessage={errors.cityError}
                />
                <CustomInput
                    label='Province'
                    onChange={onStateChange}
                    value={account.state ?? ''}
                    error={!!errors.stateError}
                    errorMessage={errors.stateError}
                />
                <CustomInput
                    label='Password'
                    onChange={onPasswordChange}
                    value={account.password}
                    error={!!errors.passwordError}
                    errorMessage={errors.passwordError}
                    type={showPassword ? 'text' : 'password'}
                    autoComplete='new-password'
                    EndAdornment={Eye}
                    endAdormentClick={() => setShowPassword(!showPassword)}
                />
                <CustomInput
                    label='Confirm Password'
                    onChange={onConfirmPasswordChange}
                    value={account.confirmPassword}
                    error={!!errors.confirmPasswordError}
                    errorMessage={errors.confirmPasswordError}
                    type={showPassword ? 'text' : 'password'}
                    autoComplete='new-password'
                    EndAdornment={Eye}
                    endAdormentClick={() => setShowPassword(!showPassword)}
                />
                <CustomRadio
                    label='Preferred communication method'
                    data={COMMUNICATION_METHOD}
                    value={account.preferredCommunicationMethod}
                    onChange={onCommunicationMethodChange}
                />
            </div>

            <CustomButton
                title='Next'
                disabled={disabled || isLoading || isLoadingParseToken}
                loading={isLoading || isLoadingParseToken}
                onClick={createAccountHandler}
                classNames='buttonsWrapper accountNext'
            />
        </div>
    )
}

export default CreateAccount;