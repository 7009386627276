import React, { useCallback, useEffect, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Header from "../../../components/Header/header";
import Home from "../../../components/Home/home";
import LandingBackground from "../../../components/LandingBackground/landingBackground";
import { Finder } from "../../../uiKit/assets/svg/find";
import { Trumpet } from "../../../uiKit/assets/svg/trumpet";
import CustomButton from "../../../uiKit/Button/CustomButton";
import CustomDialog from "../../../uiKit/CustomDialog/customDialog";
import LoginComponent from "../../Login/loginComponent";
import CampaignsInfos from "./HelperComponents/campaignInfos";
import CampaignMessages from "./HelperComponents/campaignMessages";
import CampaignWarning from "./HelperComponents/campaignWarning";

import { login } from "../../../redux/state/accountSlice";
import {
    clearCampaigns,
    getCampaignSuggestion,
    launchCampaignSuggestion,
    saveCampaignSuggestion
} from "../../../redux/state/campaignSlice";
import { setCommunityId } from "../../../redux/state/communitySlice";
import { getMember } from "../../../redux/state/memberSlice";

const SuggestionComponent = () => {

    const isLoading = useSelector(state => state.campaign.isLoading.launchCampaignSuggestion, shallowEqual);
    const isLoadingSuggestion = useSelector(state => state.campaign.isLoading.saveCampaignSuggestion, shallowEqual);
    const isLoadingGetSuggestion = useSelector(state => state.campaign.isLoading.getCampaignSuggestion, shallowEqual);

    const history = useHistory();
    const dispatch = useDispatch();
    const { campaignId } = useParams();
    const { search } = useLocation();

    const communityIdState = useSelector(state => state.community.community.id, shallowEqual);

    const communityId = new URLSearchParams(search).get('community');

    const [draftMessages, setDraftMessages] = useState(null);

    const [messages, setMessages] = useState(null);
    const [excludedMembers, setExcludedMembers] = useState([]);

    const [addMember, setAddMember] = useState(false);
    const [confirmMember, setConfirmMember] = useState(false);

    const [editedCampaign, setEditedCampaign] = useState({
        id: null,
        name: '',
        campaignTypeId: 0,
        recipient: {
            id: null,
            fullName: null,
            isVerified: false,
            email: null,
            firstName: null,
            lastName: null,
            phoneNumber: null
        },
        suggestedRecipient: {
            id: null,
            fullName: null,
            isVerified: false,
            email: null,
            firstName: null,
            lastName: null,
            phoneNumber: null
        },
        amount: 0,
        recipientId: '',
        description: '',
        excludedMembers: []
    });


    useEffect(() => {
        if (communityIdState !== communityId) {
            dispatch(setCommunityId({ communityId }))
            window.localStorage.setItem('communityId', communityId)
        }
    }, [communityIdState, communityId, dispatch])

    useEffect(() => {
        if (!!editedCampaign.suggestedRecipient?.id && !!communityId) {
            const userId = editedCampaign.suggestedRecipient.id;
            dispatch(getMember({ communityId, userId }))
                .then(unwrapResult)
                .then(({ member }) => {
                    if (member.isVerified) {
                        setConfirmMember(true)
                    } else {
                        setAddMember(true);
                    };

                })
                .catch(() => { setAddMember(true) })
        }
    }, [
        editedCampaign.suggestedRecipient?.id,
        communityId,
        dispatch
    ])

    useEffect(() => {
        if (!!communityId && !!campaignId) {
            dispatch(getCampaignSuggestion({ communityId, campaignId }))
                .then(unwrapResult)
                .then(response => {
                    const {
                        id,
                        name,
                        campaignTypeId,
                        recipient,
                        suggestedRecipient,
                        recipientId,
                        amount,
                        description,
                        messages,
                        excludedMembers,
                    } = response;
                    const ids = excludedMembers.map(member => member.userId);
                    setEditedCampaign({
                        id,
                        name,
                        campaignTypeId,
                        recipient,
                        suggestedRecipient,
                        amount,
                        recipientId,
                        description,
                        excludedMembers: ids,
                    });
                    setExcludedMembers(excludedMembers);
                    setDraftMessages(messages);
                })
                .catch(({ title }) => toast.info(title))
        }
    }, [communityId, campaignId, dispatch])

    const saveHandler = () => {

        const data = { ...editedCampaign, communityId, messages }
        dispatch(launchCampaignSuggestion({
            communityId,
            campaignId,
            data
        }))
            .then(unwrapResult)
            .then((res) => {
                dispatch(clearCampaigns());
                setTimeout(() => {
                    history.push(`/campaigns/campaign/active/${res.id}`)
                }, [1000])
            })
            .catch(({ title }) => {
                window.scrollTo(0, 0);
                toast.info(`Launching suggested draft failed: ${title}`)
            })
    }

    const saveDraftHandler = () => {

        const data = { ...editedCampaign, communityId, messages }

        dispatch(saveCampaignSuggestion({
            communityId,
            campaignId,
            data
        }))
            .then(unwrapResult)
            .then(() => history.push('/settings/admin/drafts/suggestion'))
            .catch(({ title }) => {
                window.scrollTo(0, 0);
                toast.info(`Saving suggested draft failed: ${title}`)
            })
    }

    const updateMessages = useCallback((newMessages) => setMessages(newMessages), [])

    return (
        <>
            <Header title='Drafts' onClick={() => history.push('/settings/admin/drafts/suggestion')} />
            <div className='titleInputWrapper'>
                <CampaignsInfos
                    campaign={editedCampaign}
                    setCampaign={setEditedCampaign}
                    suggestedUser={true}
                    excluded={excludedMembers}
                />
                <div className='divider' style={{ marginBottom: '8px' }} />
                <CampaignMessages
                    messages={draftMessages}
                    updateMessages={updateMessages}
                    isLoading={isLoadingGetSuggestion}
                />
                <div className='divider' />
                <CampaignWarning />
                <div className='createCampaignButtons' >
                    <CustomButton
                        title='Launch'
                        style={{ marginBottom: '16px' }}
                        classNames='mr16to0'
                        onClick={saveHandler}
                        disabled={isLoading}
                        loading={isLoading}
                    />
                    <CustomButton
                        isWhite
                        title='Save as Draft'
                        onClick={saveDraftHandler}
                        disabled={isLoadingSuggestion}
                        loading={isLoadingSuggestion}
                    />
                </div>
            </div >
            <CustomDialog
                Image={Finder}
                title="The campaign was suggested for recipient:"
                confirmOpen={addMember}
                secondDescription="This user is not a member of community. Would you like to add him as a member?"
                mainButtonTitle='Add member'
                mainButtonAction={() => history.push(`/settings/admin/drafts/suggestion/${communityId}/${campaignId}/add-member`)}
                onClose={() => setAddMember(false)}
                customDescription={{
                    available: true,
                    firstTitle: editedCampaign.suggestedRecipient?.fullName,
                    secondTitle: editedCampaign.suggestedRecipient?.email,
                    thirdTitle: editedCampaign.suggestedRecipient?.phoneNumber
                }}
            />
            <CustomDialog
                Image={Trumpet}
                title="The campaign was suggested for recipient:"
                confirmOpen={confirmMember}
                secondDescription="This user is a verified member of community. Please confirm the recipient."
                mainButtonTitle='Confirm'
                mainButtonAction={() => setConfirmMember(false)}
                onClose={() => setConfirmMember(false)}
                customDescription={{
                    available: true,
                    firstTitle: editedCampaign.suggestedRecipient?.fullName,
                    secondTitle: editedCampaign.suggestedRecipient?.email,
                    thirdTitle: editedCampaign.suggestedRecipient?.phoneNumber,
                }}
            />
        </ >
    )
}

const SuggestedCampaign = () => {

    const token = window.localStorage.getItem('token');

    const dispatch = useDispatch();

    const loginHandler = useCallback(({ email, password }) => {
        dispatch(login({ email, password }))
            .then(unwrapResult)
            .then(() => window.location.reload())
            .catch(({ title }) => console.error(title))
    }, [dispatch])

    const Login = () => (
        <LoginComponent
            onLogin={loginHandler}
            createAccount={false}
        />
    )

    if (!token) {
        return (
            <LandingBackground
                Component={Login}
                color='blueBackground'
            />
        )
    }

    return <Home Component={SuggestionComponent} />
}

export default SuggestedCampaign;