import { FetchAPI } from './main';

export const CommunityAPI = {
    getCommunityTypes: () => FetchAPI.get('/community/communities/types'),
    getCommunities: () => FetchAPI.get(`/community/communities`),
    getCommunity: (communityId) => FetchAPI.getJson(`/community/communities/${communityId}`),
    getCommunityInfo: (communityId) => FetchAPI.getJson(`/community/communities/${communityId}/info`),
    leaveCommunity: (communityId) => FetchAPI.postJson(`/community/communities/${communityId}/leave/`),
    updateCommunity: (data) => {

        const { communityId, communityTypeId, name, file, location } = data;

        const formData = new FormData();

        formData.append('communityTypeId', communityTypeId);
        formData.append('Name', name);

        if (!!location) {
            formData.append('location', location);
        }
        if (!!file) {
            formData.append('file', file);
        }

        return FetchAPI.put(`/community/communities/${communityId}`, formData);
    },
    deleteCommunity: (communityId) => FetchAPI.deleteJson(`/community/communities/${communityId}`),
    getCommunityLink: (communityId) => FetchAPI.getJson(`/community/communities/${communityId}/invitation-link`),
    approveCommunity: (communityId) => FetchAPI.postJson(`/community/communities/${communityId}/approve`),
    createCommunity: ({ data }) => {

        const {
            communityTypeId,
            name,
            file,
            location,
            description,
        } = data;

        const formData = new FormData();

        formData.append('communityTypeId', communityTypeId);
        formData.append('name', name);

        if (!!location) {
            formData.append('location', location);
        }
        if (!!description) {
            formData.append('description', description);
        }
        if (!!file) {
            formData.append('file', file);
        }

        return FetchAPI.post('/community/communities', formData);
    },
    //paginations
    getApprovedCommunities: ({
        pageSize,
        page,
        searchString
    }) => FetchAPI.getJson(`/community/communities/${pageSize}/${page}?searchString=${searchString}`),
    getNotApprovedCommunities: ({
        pageSize,
        page,
        searchString
    }) => FetchAPI.getJson(`/community/communities/${pageSize}/${page}/not-approved?searchString=${searchString}`),
    getAllCommunities: ({
        pageSize,
        page,
        searchString
    }) => FetchAPI.getJson(`/community/communities/${pageSize}/${page}/full-info?searchString=${searchString}`),
}