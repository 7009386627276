import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Home from "../../components/Home/home";
import LandingBackground from "../../components/LandingBackground/landingBackground";
import { getCommunities, setCommunityId } from "../../redux/state/communitySlice";
import Campaigns from "./campaignsForAllMembers";

const CampaignsWrapper = () => {

    const token = window.localStorage.getItem('token');
    const communityId = window.localStorage.getItem('communityId');

    const communities = useSelector(state => state.community.memberCommunities, shallowEqual);
    const isLoading = useSelector(state => state.community.isLoading.getCommunities, shallowEqual);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!!token) {
            dispatch(getCommunities())
        }
    }, [token, dispatch])


    useEffect(() => {
        if (!!communityId) {
            dispatch(setCommunityId({ communityId }))
        }
    }, [communityId, dispatch])

    if (!token) {
        return <LandingBackground Component={Campaigns} color='blueBackground' />
    }

    if (!isLoading && !!communities && !communities.length) {
        return <LandingBackground Component={Campaigns} color='blueBackground' />
    }

    return !!communities && (
        <Home Component={Campaigns} />
    )
}

export default CampaignsWrapper;