import React from "react";
import { yellow100, yellow300 } from "../../../../uiKit/assets/colors/colors";
import { Info } from "../../../../uiKit/assets/svg/icons";
import Title from "../../../../uiKit/Title/Title";

const CampaignWarning = () => (
    <div style={{
        display: 'flex',
        borderRadius: '20px',
    }}
        className='boxShadow'
    >
        <div style={{
            backgroundColor: yellow300,
            padding: '17.5px 10px',
            borderRadius: '12px 0px 0px 12px',
        }}
        >
            <Info />
        </div>
        <div style={{
            backgroundColor: yellow100,
            padding: '10px',
            borderRadius: '0px 12px 12px 0px',
            alignSelf: 'center',
            width: '100%'
        }}
        >
            <Title
                text='After launch, you will not be able to edit the campaign.'
                fontWeight={400}
            />
            <Title
                text='Please make sure all data is entered correctly.'
                fontWeight={400}
            />
        </div>
    </div>
)

export default CampaignWarning;