import { CommunityAPI } from './CommunityAPI';
import { CampaignAPI } from './CampaignAPI';
import { MemberAPI } from './MemberAPI';
import { MessagesAPI } from './MessagesAPI';
import { DraftAPI } from './DraftAPI';
import { PaymentAPI } from './PaymentAPI';
import { AccountAPI } from './AccountAPI';
import { NotificationAPI } from './NotificationAPI';

const storedToken = window.localStorage.getItem('token');

let token = JSON.parse(storedToken)?.access_token;
const setToken = (newToken) => {
    token = newToken;
};

const API_URL = process.env.NODE_ENV === 'development' ? 'https://ca.ourcommunity.one/api' : 'https://' + window.location.host + '/api';

export const FetchAPI = {
    post: (urlStr, data) => {
        return fetch(`${API_URL}${urlStr}`, {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => handleResponse({ response, urlStr, data, method: 'post' }));
    },
    postJson: (urlStr, data) => {
        return fetch(`${API_URL}${urlStr}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        }).then((response) => handleResponse({ response, urlStr, data, method: 'postJson' }));

    },
    put: (urlStr, data) => {
        return fetch(`${API_URL}${urlStr}`, {
            method: 'PUT',
            body: data,
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => handleResponse({ response, urlStr, data, method: 'put' }));

    },
    putJson: (urlStr, data) => {
        return fetch(`${API_URL}${urlStr}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        }).then((response) => handleResponse({ response, urlStr, data, method: 'putJson' }));

    },
    deleteJson: (urlStr, data) => {
        return fetch(`${API_URL}${urlStr}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        }).then((response) => handleResponse({ response, urlStr, data, method: 'deleteJson' }));

    },
    getJson: (urlStr) => {
        return fetch(`${API_URL}${urlStr}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => handleResponse({ response, urlStr, method: 'getJson' }));
    },
    get: (urlStr) => {
        return fetch(`${API_URL}${urlStr}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => handleResponse({ response, urlStr, method: 'get' }));
    },
    getJsonPublic: (urlStr, data) => {
        return fetch(`${API_URL}${urlStr}`, {
            headers: {
                'Authorization': 'Bearer '
            }
        }).then((response) => handleResponse({ response, urlStr, method: 'getJson' }));
    },

}

let loading = false;

const setLoading = (flag) => loading = flag;

const refresh = async ({ urlStr, data, method }) => {

    const token = window.localStorage.getItem('refresh-token');

    if (!token) {
        console.error('Refresh token is missing');
        return
    }

    try {
        const newToken = await AccountAPI.refreshToken(token);

        setToken(newToken.token.access_token);
        window.localStorage.setItem('token', JSON.stringify(newToken.token));
        window.localStorage.setItem('refresh-token', newToken.token.refresh_token);
        setLoading(false);
        const callback = FetchAPI[method];

        return callback(urlStr, data);
    }
    catch (error) {
        console.error(error);
        window.localStorage.removeItem('token');
        window.location.reload(false);
    }
}

const recursion = async ({ urlStr, data, method }) => {
    const token = window.localStorage.getItem('refresh-token');

    if (!token) {
        console.error('Refresh token is missing');
        return
    }
    
    const callback = FetchAPI[method];

    return callback(urlStr, data);
}

const handleResponse = async ({ response, urlStr, data, method }) => {
    var responseObj = await response.json();

    if (response.status === 401) {
        if (!loading) {
            setLoading(true);
            return await refresh({ urlStr, data, method });
        } else {
            return await recursion({ urlStr, data, method });
        }
    }

    if (response.ok) {
        return responseObj;
    }
    else {
        return {
            error: responseObj
        }
    }
}

const API = {
    setToken,
    CommunityAPI,
    MemberAPI,
    CampaignAPI,
    MessagesAPI,
    PaymentAPI,
    DraftAPI,
    AccountAPI,
    NotificationAPI
}

export default API;